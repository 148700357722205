import { computed, nextTick, ref, watch, type ComputedRef, type Ref } from "vue"
import type { UnionToIntersection, ConditionalKeys } from "type-fest"

type AnyRef<T> = Ref<T> | ComputedRef<T>

type Path<T, Prefix extends string = ''> = T extends object ? {
  [K in keyof T]: K extends string ? (
    NonNullable<T[K]> extends object ? (
      `${Prefix}${K}` | Path<T[K], `${Prefix}${K}.`>
    ) : (
      `${Prefix}${K}`
    )
  ) : never
}[keyof T] : T

function objectPropertyAtPath<T>(object: T, path: Path<T>) {
  const keys = (path as string).split('.')

  let value: unknown = object
  for (const key of keys) {
    if (value && typeof value === 'object') {
      value = value[key as keyof typeof value]
    } else {
      value = undefined
    }
  }

  return value
}

function wrapSearchGetter <T extends object>(object: ReturnType<SearchGetter<T>>) {
  if (typeof object === 'undefined' || object === null) return []
  else if (Array.isArray(object)) return object
  else return [object]
}

/**
 * Finds the first searchable property in an object from a list of keys and returns it as a string.
 * 
 * @template T - The type of the object.
 * @template TSearchableProperty - The type of the keys that can be searched within the object.
 * 
 * @param {T} object - The object to search within.
 * @param {TSearchableProperty[]} searchThroughKeys - An array of keys to search for within the object.
 * 
 * @returns The value of the first found property as a string, or an empty string if none of the properties are found.
 */
function findSearchableProperty<T extends object, TSearchableProperty extends Path<T>>(object: T, searchThroughKeys: Array<TSearchableProperty | SearchGetter<T>>) {
  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  return String(searchThroughKeys.reduce<unknown | undefined>((memo, key) => {
    if (typeof memo === 'undefined' || memo === null) {
      if (isSearchGetter(key)) {
        return wrapSearchGetter(key(object)).reduce<unknown | undefined>((localMemo, value) => localMemo ?? value, undefined)
      } else {
        return objectPropertyAtPath(object, key)
      }
    } else {
      return memo
    }
  }, undefined) ?? '')
}

function cleanString <T extends string | undefined | null>(string: NoInfer<T>) {
  return string?.trim().toLowerCase()
}

/**
 * Tokenizes a given string into an array of words. The string is first trimmed and converted to lowercase.
 * If the input string is undefined or null, an empty array is returned.
 * 
 * @param {string | undefined | null} string - The input string to be tokenized.
 * 
 * @returns An array of words from the tokenized string. Returns an empty array if the input is undefined or null.
 */
function tokenizeTerm (string: string | undefined | null) {
  const cleanedString = cleanString(string)

  if (cleanedString) {
    return cleanedString.split(/\s+/)
  } else {
    return []
  }
}

/**
 * Compares two arrays of tokens to determine if the sequence of tokens to find exists within the tokens.
 * 
 * @param {string[]} tokensToFind - The array of tokens to find within the other array.
 * @param {string[]} tokens - The array of tokens to be searched.
 * 
 * @returns Returns true if the sequence of tokens to find exists within the tokens, otherwise false.
 */
function compareTokens (tokensToFind: string[], tokens: string[]) {
  if (tokens.length === 0 || tokensToFind.length === 0) return false
  for (let i = 0; i < tokens.length; i++) {
    if (tokens.length - i < tokensToFind.length) {
      // There is not enough tokens to match against, return false
      return false
    } else if (tokens[i].startsWith(tokensToFind[0])) {
      for (let j = 1; j <= tokensToFind.length; j++) {
        // Hacky way to ensure that we always return true on the last token
        if (j === tokensToFind.length) return true
        // Break out of the loop if a token does not match the token we are looking for
        else if (!tokens[i + j].startsWith(tokensToFind[j])) {
          break
        }
      }
    }
  }

  return false
}

type SearchGetter<T> = (object: T) => undefined | null | string | string[]

function isSearchGetter<T extends object, TSearchableProperty extends Path<T>>(object: TSearchableProperty | SearchGetter<T>): object is SearchGetter<T> {
  return typeof object === 'function'
}

function matches <T extends object, TSearchableProperty extends Path<T>>(
  object: T,
  searchThroughKeys: Array<TSearchableProperty | SearchGetter<T>>,
  searchThroughAllKeys: boolean | undefined,
  exclude: ((object: T) => boolean) | undefined,
  matchMethod: (value: string) => boolean | undefined
) {
  if (exclude?.(object)) {
    // If item is excluded from search, just return true
    return true
  } else if (searchThroughAllKeys) {
    return searchThroughKeys.some((key) => {
      if (isSearchGetter(key)) {
        return wrapSearchGetter(key(object)).some((value) => {
          if (typeof value === 'undefined' || value === null) {
            // If property is undefined or null, just return false
            return false
          } else {
            // Otherwise stringify it and compare
            return matchMethod(String(value))
          }
        })
      } else {
        // Retrieve property at specific path
        const value = objectPropertyAtPath(object, key)
        if (typeof value === 'undefined' || value === null) {
          // If property is undefined or null, just return false
          return false
        } else {
          // Otherwise stringify it and compare
          // eslint-disable-next-line @typescript-eslint/no-base-to-string
          return matchMethod(String(value))
        }
      }
    })
  } else {
    // Find first searchable property and compare
    return matchMethod(findSearchableProperty(object, searchThroughKeys))
  }
}

export type SearchOptions = {
  /** Search using fuzzy search  */
  fuzzy?: boolean
  /** Search by all specified properties */
  all?: boolean
}

/**
 * Filters a collection of objects based on a search term and specified keys to search through.
 * 
 * @template T - The type of the objects in the collection.
 * @template TSearchableProperty - The type of the keys that can be searched within the objects.
 * 
 * @param {T[]} collection - The collection of objects to filter.
 * @param {string | undefined | null} searchTerm - The search term used to filter the collection.
 * @param {TSearchableProperty[]} searchThroughKeys - The keys to search through within each object in the collection.
 * 
 * @returns The filtered collection of objects.
 */
export function getFilteredCollection<T extends object, TSearchableProperty extends Path<T>>(
  collection: T[],
  searchTerm: string | undefined | null,
  searchThroughKeys: Array<TSearchableProperty | SearchGetter<T>>,
  options: SearchOptions & {
    /** Exclude specific items from being filtered */
    exclude?: (object: T) => boolean
  } = {
    fuzzy: false,
    all: false,
    exclude: undefined
  }
) {
  const cleanedString = cleanString(searchTerm)
  if (!cleanedString) {
    return collection
  } else if (options.fuzzy) {
    const tokens = tokenizeTerm(searchTerm)
    if (tokens.length === 0) {
      return collection
    }

    return collection.filter((object) => matches(object, searchThroughKeys, options.all, options.exclude, (value) => compareTokens(tokens, tokenizeTerm(value))))
  } else {
    return collection.filter((object) => matches(object, searchThroughKeys, options.all, options.exclude, (value) => cleanString(value)?.includes(cleanedString)))
  }
}

export function useSearch<T extends object, TSearchableProperty extends Path<T>, TSearchableNestedProperty extends ConditionalKeys<UnionToIntersection<T>, T[]>>(
  collection: AnyRef<T[]>,
  collectionSearchableProperties: Array<TSearchableProperty | SearchGetter<T>>,
  searchElementVisible: AnyRef<boolean>,
  options: SearchOptions & {
    /** Property that includes additional nested collections to be searched through */
    includeNestedProperty?: TSearchableNestedProperty,
    /** Exclude specific items from being filtered */
    exclude?: (object: T) => boolean
  } = {
    includeNestedProperty: undefined,
    fuzzy: false,
    all: false,
    exclude: undefined
  }
) {
  const searchElement = ref<HTMLInputElement>()
  const searchModel = ref('')
  const searchClear = () => searchModel.value = ''
  const searchValue = computed(() => searchModel.value.trim())

  const getFilteredCollection = (collection: T[], matchMethod: (value: string) => boolean | undefined) => collection.reduce<T[]>((memo, object) => {
    if (typeof options.includeNestedProperty !== 'undefined' && options.includeNestedProperty in object) {
      const nestedCollection = getFilteredCollection(object[options.includeNestedProperty as unknown as keyof T] as T[], matchMethod)

      if (nestedCollection.length > 0) {
        memo.push({
          ...object,
          [options.includeNestedProperty]: nestedCollection
        })
      }
    } else if (matches(object, collectionSearchableProperties, options.all, options.exclude, matchMethod)) {
      memo.push(object)
    }

    return memo
  }, [])

  const getFlatCollection = (collection: T[], nestingLevel = 0): (T & { _nestingLevel: number })[] => {
    return collection.flatMap((object) => {
      if (typeof options.includeNestedProperty !== 'undefined' && options.includeNestedProperty in object) {
        return [
          {
            ...object,
            _nestingLevel: nestingLevel
          },
          ...getFlatCollection(
            object[options.includeNestedProperty as unknown as keyof T] as T[],
            nestingLevel + 1
          )
        ]
      } else {
        return {
          ...object,
          _nestingLevel: nestingLevel
        }
      }
    })
  }

  watch(searchElementVisible, (isVisible) => {
    if (isVisible) void nextTick(() => searchElement.value?.focus())
    else {
      searchClear()
    }
  })

  const searchCollection = computed(() => {
    const cleanedString = cleanString(searchValue.value)
    if (!cleanedString) {
      return collection.value
    } else if (options.fuzzy) {
      const tokens = tokenizeTerm(searchValue.value)
      if (tokens.length === 0) {
        return collection.value
      }

      return getFilteredCollection(collection.value, (value) => compareTokens(tokens, tokenizeTerm(value)))
    } else {
      return getFilteredCollection(collection.value, (value) => cleanString(value)?.includes(cleanedString))
    }
  })

  const searchCollectionFlat = computed(() => getFlatCollection(searchCollection.value))

  return {
    searchElement,
    searchModel,
    searchCollection,
    searchCollectionFlat,
    searchClear,
    searchValue
  }
}
