<template>
  <component
    :is="tagName"
    class="min-h-9 h-9 text-sm flex items-center px-3 gap-3 w-full whitespace-nowrap"
    :disabled="props.item.disabled"
    :title="props.item.title ?? props.item.label"
    :class="computedWrapperClass"
    :href="props.item.href"
    data-component-name="VContextMenuItem"
    :tabindex="props.item.type === 'checkbox' && !props.item.disabled ? 0 : undefined"
    @click="onClick"
    @keydown.space="onClickCheckbox"
  >
    <VIcon v-if="props.subItem" size="xs" name="Outline/drag-indicator-vertical" />
    <VIcon v-if="props.item.icon" :name="props.item.icon" :class="{'opacity-50' : props.item.type === 'checkbox' && props.item.disabled}" />
    <div class="overflow-ellipsis overflow-hidden" :class="{'opacity-50' : props.item.type === 'checkbox' && props.item.disabled}">
      {{ props.item.label }}
    </div>
    <input
      v-if="props.item.type === 'checkbox'"
      :checked="getItemValue(props.item)"
      type="checkbox"
      :disabled="props.item.disabled"
      class="h-4 w-4 rounded border-neutral-300 focus:ring-blue-500 text-primary-500 disabled:text-neutral-200 ml-auto pointer-events-none"
      :tabindex="-1"
      @click="onClickCheckbox"
    >
    <VIcon v-if="props.item.type === 'menu'" class="ml-auto" name="Solid/chevron-right" />
  </component>
</template>
 
<script setup generic="T" lang="ts">
import { computed } from 'vue';
import type { V } from '@component-utils/types';
import VIcon from '../labels/VIcon.vue';
import { getItemValue } from '../../utils/utils';


defineOptions({
  name: 'VContextMenuItem'
})

const props = defineProps<{
    item: V.ContextMenu.Item<T>
    subItem?: boolean
    context?: T
  }>()

const emit = defineEmits<{ close: [] }>()

const tagName = computed(() => {
  if(props.item.type === 'checkbox') return 'label'
  else if(props.item.type === 'button' || props.item.type === 'menu') return 'href' in props.item ? 'a' : 'button'
  return undefined
})

const onClick = () => {
  if(props.item.type === 'button' && !('href' in props.item)){
    props.item.action(props.context)
    emit('close')
  }
}

const onClickCheckbox = () => {
  if (props.item.type !== 'checkbox' || props.item.disabled) return

  const value = !getItemValue(props.item)
  // eslint-disable-next-line vue/no-mutating-props
  if (typeof props.item.value === 'boolean') props.item.value = value
  else props.item.value.set(value)

}

const computedWrapperClass = computed (() => {
  return {
    'cursor-not-allowed text-neutral-400': props.item.disabled, 
    'hover:bg-neutral-150 text-neutral-950': !props.item.disabled,
    'cursor-not-allowed': props.item.type === 'checkbox' && props.item.disabled, 
    'cursor-pointer': props.item.type === 'checkbox' && !props.item.disabled,
    'pr-2': props.item.type === 'menu'
  }
})
</script>
