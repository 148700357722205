<template>
  <VInput v-bind="inputProps" ref="input" v-model="modelValue" type="search" allow-empty data-component-name="VSearch">
    <template v-if="icon" #[iconPosition]>
      <VIcon :name="icon" />
    </template>
  </VInput>
</template>
<script lang="ts" setup>
import VInput from './VInput.vue';
import VIcon from '../labels/VIcon.vue';
import type { SharedProps, ValidationProps } from './helpers/types';
import { computed, useTemplateRef } from 'vue';
import { useFallthroughProps } from './helpers/fallthrough';
import type { Icon } from '@icons/index';

defineOptions({
  name: 'VSearch',
  inheritAttrs: false
})

const props = defineProps<SharedProps & ValidationProps<string> & {
  placeholder?: string
  focus?: boolean
  buffered?: boolean
  centered?: boolean
  maxLength?: number
  icon?: Icon | null
  iconPosition?: 'prefix' | 'suffix'
}>()

const icon = computed(() => typeof props.icon === 'string' ? props.icon : props.icon === null ? false : 'Solid/search')
const iconPosition = computed(() => props.iconPosition ?? 'prefix')

const inputProps = useFallthroughProps(props)

const modelValue = defineModel<undefined | null | string>({ required: true })

const inputElement = useTemplateRef('input')

defineExpose({
  focus: () => {
    inputElement.value?.focus()
  },
  get isValid () {
    return inputElement.value?.isValid ?? false
  }
})
</script>
