<template>
  <p :id="props.id" class="modern-color-theme font-poppins text-sm text-secondary" data-component-name="VSDescription">
    <slot />
  </p>
</template>
<script setup lang="ts">
const props = defineProps<{
  id?: string
}>()

defineOptions({
  name: 'VSDescription'
})
</script>